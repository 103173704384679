import React from 'react';
import './App.css';
import bittechlogo from './assets/Bittechlogo.svg';
import coolImage from './assets/cool.png';

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${coolImage})` }}>
      {/* Subtítulo */}
      <div className="subtitle-container">
        <p className="subtitle">
          MystiChat.AI We'll be back soon with an update you'll love. MystiChat 3.0 is coming this February with a reinvention of the most incredible generative AI interaction ever. Stay Tune !
        </p>
      </div>

      {/* Footer */}
      <div className="bittech-footer">
        <div className="bittechlogo">
          <img src={bittechlogo} alt="Bittech-Logo" />
        </div>
        <div className="bittech-info">
          <h2>Bittech Network</h2>
          <p>Barcelona, Spain</p>
          <a href="http://www.bittechnetwork.com">www.bittechnetwork.com</a><br/>
          <a href="mailto:networkbittech@gmail.com">networkbittech@gmail.com</a>
        </div>
      </div>
    </div>
  );
}

export default App;












